import React from "react";
import { Fade } from "views/animation";
import "./ContactUsStyle.css";
import { Link } from "react-router-dom";
import { useWindowSize } from "hooks";

const texts = ["Contact Us", "Let's work together!"];

const breakPoint = 1200;

const ContactUsBtn = () => {
  const [text, setText] = React.useState("Contact Us");

  return (
    <Link to="/contact-us" className="contact-us-btn slide_from_right" onMouseEnter={() => setText(texts[1])} onMouseLeave={() => setText(texts[0])}>
      {text === texts[0] ? text : ""}
      {text === texts[1] && (
        <Fade duration="700ms" show={text === texts[1]}>
          {texts[1]}
        </Fade>
      )}
    </Link>
  );
};

export const ContactUsButton = () => {
  const { width } = useWindowSize();

  if (width < breakPoint) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", marginBottom: "5rem" }}>
        <ContactUsBtn />
      </div>
    );
  }
  return <ContactUsBtn />;
};
