import React from "react";
import Sidebar from "./components/Sidebar";
import { useContextLayout } from "./ContextLayout";
import "./layout.css";
import FULL_WHITE_LOGO from "assets/full-logo-white.png";
import { useLocation } from "react-router-dom";

export const Layout = (props) => {
  const { sidebarOpen } = useContextLayout();
  const { pathname } = useLocation();
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    if (sidebarOpen) {
      setTimeout(() => {
        setShouldRender(false);
      }, 1000);
    } else {
      setShouldRender(true);
    }
  }, [sidebarOpen, setShouldRender]);

  const logoShouldRender = pathname === "/" && !sidebarOpen;
  return (
    <div className="app">
      <img src={FULL_WHITE_LOGO} alt="full-white-logo" className={`animated-logo${!logoShouldRender ? "-hidden" : ""}`} />
      <Sidebar />
      <div className="content">{shouldRender && props.children}</div>
    </div>
  );
};
