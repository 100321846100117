import React from "react";
import { ImageSlider } from "./ImageSlider";
import { useWindowSize } from "hooks";

const breakPoint = 1200;

export const ImageSliderLayout = ({ images }) => {
  const { width } = useWindowSize();

  if (!images) return null;

  if (width < breakPoint) {
    return <ImageSlider className="animation-img-slider-left" images={images} scrollbar={true} />;
  }

  const mid = images.length / 2;
  const leftImages = images.slice(0, mid);
  const rightImages = images.slice(mid, images.length);

  return (
    <div className="d-flex">
      <ImageSlider className="g-ml animation-img-slider-left" images={leftImages} scrollbar={true} />
      <ImageSlider className="g-mr animation-img-slider-right" images={rightImages} scrollbar={false} />
    </div>
  );
};
