import React from "react";
import "./css/page.css";
import { useGetBranches } from "api/branches";
import { Title } from "./Title";
import MAP_IMAGE from "assets/images/map.jpg";
import Canvas from "./Canvas";
import ResizeObserver from "react-resize-observer";
import BranchDetails from "./BranchDetails";
import "./css/map.css";

const BranchesPage = () => {
  const { data } = useGetBranches();
  const branches = data || [];
  const imgRef = React.useRef(null);
  const [sizes, setSizes] = React.useState({ width: 1, height: 1 });

  const [clickedPoint, setClickedPoint] = React.useState(null);
  const [selectedBranch, setSelectedBranch] = React.useState(null);

  const handleClick = (e) => {
    const xPos = e.nativeEvent.offsetX;
    const yPos = e.nativeEvent.offsetY;
    setClickedPoint({ xPos, yPos });
  };

  return (
    <div className="branches-page">
      <div className="row b-row">
        <div className="col-xl-6">
          <Title />
        </div>
      </div>
      <div className="map_container">
        <div style={{ margin: 0, padding: 0, position: "relative" }}>
          <img
            ref={imgRef}
            onClick={handleClick}
            style={{
              padding: 0,
              maxWidth: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            src={MAP_IMAGE}
            alt=""
          />
          <ResizeObserver
            onResize={(rect) => {
              setSizes({ width: rect.width, height: rect.height });
            }}
          />
          <Canvas
            clickedPoint={clickedPoint}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            initialDots={branches}
            width={sizes.width}
            height={sizes.height}
            style={{
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
            sizes={sizes}
          />
        </div>
      </div>
      <BranchDetails branch={selectedBranch} />
    </div>
  );
};

export default BranchesPage;
