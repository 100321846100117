import React from "react";
import "./TextInput.css";
import { useFormikContext } from "formik";

export const TextInput = ({ name, label, placeholder, ...props }) => {
  const formik = useFormikContext();
  const [_placeholder, setPlaceholder] = React.useState("");
  const [_label, setLabel] = React.useState(label);

  return (
    <div className={`input-containenr ${props.className ? props.className : ""}`}>
      <input
        type="text"
        placeholder={_placeholder}
        name={name}
        autoComplete="off"
        onFocus={() => {
          setPlaceholder(placeholder);
          setLabel(label + " *");
        }}
        required
        onBlur={() => {
          setPlaceholder("");
          setLabel(label);
        }}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        value={formik.values[name]}
        {...props}
      />
      <label htmlFor={name} className="label-name">
        <span className="content-name">{_label}</span>
      </label>
    </div>
  );
};
