const SITE_NAME = "Sky Hawks";

export const navConfig = [
  {
    id: 1,
    label: "Home",
    navLink: "/",
  },
  {
    id: 2,
    label: "About Us",
    navLink: "/about-us",
  },
  {
    id: 3,
    label: "Our Products",
    navLink: "/our-products",
  },
  {
    id: 4,
    label: "Gallery",
    navLink: "/gallery",
  },
  {
    id: 5,
    label: "Branches",
    navLink: "/branches",
  },
];

const documentTitle = {
  "/about-us": `${SITE_NAME} - About Us`,
  "/our-products": `${SITE_NAME} - Products`,
  "/gallery": `${SITE_NAME} - Gallery`,
  "/branches": `${SITE_NAME} - Branches`,
  "/contact-us": `${SITE_NAME} - Contact Us`,
  "/": SITE_NAME,
};

export const paths = ["/about-us", "/our-products", "/gallery", "/branches", "/contact-us", "/"];

export const getTitle = (path) => {
  for (const key in documentTitle) {
    if (path.includes(key)) {
      return documentTitle[key];
    }
  }
  return SITE_NAME;
};
