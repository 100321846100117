import React from "react";
import "./css/footer.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useBreakPointsValues } from "hooks";
import StopIcon from "@mui/icons-material/Stop";
import { useGetHomeVideo } from "api/home";
import {baseURL} from "api/config"

export const Footer = ({ activeSlider, setActiveSlider, totalSlides, playVideo, togglePlay }) => {
  const { data: videoURL } = useGetHomeVideo();
  const fontSize = useBreakPointsValues("small", "medium", "large");
  const total = React.useMemo(() => {
    const arr = [];
    for (let i = 0; i < totalSlides; i++) arr.push(i);
    return arr;
  }, [totalSlides]);
  const width = 100 / totalSlides;

  const style = playVideo ? { position: "fixed", zIndex: 2 } : {};
  return (
    <>
      <div className="footer-container" style={style}>
        <button onClick={togglePlay} className="video-btn">
          {playVideo ? <StopIcon fontSize={fontSize} style={{ color: "#fff" }} /> : <PlayArrowIcon fontSize={fontSize} style={{ color: "#fff" }} />}
        </button>
        <div className="footer-dashes-wrapper">
          {total.map((idx) => (
            <div
              className={`f-dash ${idx === activeSlider ? "f-dash-active" : ""}`}
              key={idx}
              style={{ width: `${width || 0}%`, marginRight: idx === totalSlides - 1 ? "0" : "1rem" }}
              onClick={() => setActiveSlider(idx)}
            ></div>
          ))}
        </div>
      </div>
      {playVideo && (
        <video className="background-video" autoPlay loop muted>
          <source src={videoURL ? `${baseURL}/${videoURL}` : ""} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};
