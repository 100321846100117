import React from "react";
import { useSocialLinks } from "api/siteConfig";
import FB from "assets/icons/facebook.png";
import IG from "assets/icons/instagram.png";
import WA from "assets/icons/whatsapp.png";
import IN from "assets/icons/linkedin.png";

export const SocialLinks = () => {
  const { data: urls } = useSocialLinks();

  return (
    <div className="social-links">
      {urls?.facebook && (
        <a href={urls?.facebook || "/"} target="_blank" rel="noreferrer">
          <img src={FB} alt="FB" />
        </a>
      )}
      {urls?.instagram && (
        <a href={urls?.instagram || "/"} target="_blank" rel="noreferrer">
          <img src={IG} alt="IG" />
        </a>
      )}
      {urls?.whatsapp && (
        <a href={urls?.whatsapp || "/"} target="_blank" rel="noreferrer">
          <img src={WA} alt="WA" />
        </a>
      )}
      {urls?.linkedin && (
        <a href={urls?.linkedin || "/"} target="_blank" rel="noreferrer">
          <img src={IN} alt="IN" />
        </a>
      )}
    </div>
  );
};
