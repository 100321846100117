import React from "react";
import "./ImageSlider.css";
import { baseURL } from "api/config";

export const ImageSlider = ({ images, scrollbar = false, className }) => {
  if (!images) return null;

  return (
    <div className={`img-slider-container ${className} ${scrollbar ? "img-slider-scrollbar" : "img-slider-hide-scrollbar"}`} dir={scrollbar ? "rtl" : "ltr"}>
      {images.map((img, index) => (
        <img src={`${baseURL}/${img}`} alt="" key={index} />
      ))}
    </div>
  );
};
