import { baseURL, useGetQuery } from "api/config";
import axios from "axios";
import { useMutation } from "react-query";

const API = {
  CONTACT_US_SERVICES: `${baseURL}/api/services`,
  SUBMIT_FORM: `${baseURL}/api/contact-us-forms`,
};

export const useGetServices = () => useGetQuery("CONTACT_US_SERVICES", API.CONTACT_US_SERVICES);

export const useSubmitFormMutation = () => {
  return useMutation(async (form) => {
    await axios.post(API.SUBMIT_FORM, form);
  });
};
