import React from "react";
import "./DashedLine.css";


export const DashedLine = () => {
  return (
    <>
      <div className="ab-line">
        <div className="ab-line-first"></div>
        <div className="ab-line-second"></div>
        <div className="ab-line-third"></div>
      </div>
    </>
  );
};
