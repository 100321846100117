import React from "react";
import "./contact-us.css";
import { Formik, Form } from "formik";
import { TextInput } from "components/TextInput";
import { useGetServices, useSubmitFormMutation } from "api/contactUs";
import { SubmitButton } from "./SubmitButton";
import { SelectInput } from "components/SelectInput";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactUs = () => {
  const [snackOpen, setSnackOpen] = React.useState(false);
  const { data: services } = useGetServices();
  let options = ["Services"];
  if (services) options = [...options, ...services];

  const resetRef = React.useRef(null);
  const mutation = useSubmitFormMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      setSnackOpen(true);
      resetRef.current.click();
    }
  }, [mutation.isSuccess]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleSubmit = (values) => {
    if (mutation.isLoading) {
      return;
    }
    mutation.mutate(values);
  };

  return (
    <div className="contact-us-container">
      <h1 className="title">Contact Us</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <Form className="contact-us-form">
            <div className="row">
              <div className="col-xl-6">
                <TextInput name="name" label="Name" placeholder="Your Name" className="w-lg" />
              </div>
              <div className="col-xl-6">
                <TextInput name="email" label="Email" placeholder="Your Email" className="w-lg" />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <TextInput name="phone" label="Phone" placeholder="Your Phone" className="w-lg" />
              </div>
              <div className="col-xl-6">
                <TextInput name="subject" label="Subject" placeholder="Enter Subject" className="w-lg" />
              </div>
            </div>
            <SelectInput name="services" label="Services" options={options} />

            <TextInput name="message" label="Your Message" placeholder="Enter Your Message" className="s-row" />

            <div className="center-md">
              <SubmitButton isLoading={mutation.isLoading} />
            </div>
            <button ref={resetRef} style={{ display: "none" }} type="reset" />
          </Form>
        )}
      </Formik>
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thank you for contacting us.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;

var initialValues = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  services: "Services",
  message: "",
};
