import React from "react";
import { useProducts } from "api/products";
import "./ProductsSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useBreakPointsValues } from "hooks";
import { Scroll } from "./Scroll";
import { baseURL } from "api/config";

const options = {
  loop: true,
  centeredSlides: true,
  slidesPerView: 3,
};

export const ProductsSlider = ({ active, setActive }) => {
  const { data: products } = useProducts();
  const [my_swiper, set_my_swiper] = React.useState({});
  const fontSize = useBreakPointsValues("small", "medium", "large");

  if (!products) return null;
  if (products && products.length === 0) return null;
  return (
    <div className="pro-sli-page">
      <div className="pro-sli-container">
        <div className="sli-nav-wrapper sli-nav-wrapper-left">
          <button onClick={() => my_swiper.slidePrev()}>
            <ChevronLeftIcon fontSize={fontSize} />
          </button>
        </div>
        <div className="big-ring">
          <div className="small-ring"></div>
        </div>
        <Swiper
          onInit={(ev) => {
            set_my_swiper(ev);
          }}
          {...options}
          className="pro-swiper"
          onSlideChange={(swiper) => setActive(swiper.realIndex)}
        >
          {products?.map((pro, index) => {
            return (
              <SwiperSlide className="sli-img-container" style={{ width: "100px" }} key={index}>
                <img src={`${baseURL}/${pro.image}`} className={my_swiper.realIndex === index ? "sli-img-active" : "sli-img"} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="sli-nav-wrapper sli-nav-wrapper-right">
          <button onClick={() => my_swiper.slideNext()}>
            <ChevronRightIcon fontSize={fontSize} />
          </button>
        </div>
      </div>
      <div className="pro-details">
        <p className="pro-header">{products && products.length > 0 && products[active].header}</p>
        <h1 className="pro-product_name">{products && products.length > 0 && products[active].product_name}</h1>
        <p className="pro-desc">{products && products.length > 0 && products[active].description}</p>
        <Scroll active={active} total={products && products.length > 0 && products.length} />
      </div>
    </div>
  );
};
