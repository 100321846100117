import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useBreakPointsValues } from "hooks";
import "./css/navs.css";

export const Nav = ({ activeSlider, setActiveSlider, totalSlides, style }) => {
  const fontSize = useBreakPointsValues("small", "large", "large");

  const prev = () => {
    if (totalSlides === 0) return;
    setActiveSlider((v) => {
      return v === 0 ? totalSlides - 1 : v - 1;
    });
  };
  const next = () => {
    if (totalSlides === 0) return;
    setActiveSlider((v) => {
      return v === totalSlides - 1 ? 0 : v + 1;
    });
  };

  return (
    <div className="home-nav-wrapper" style={style}>
      <button className="home-nav-left" onClick={prev}>
        <ChevronLeftIcon fontSize={fontSize} />
      </button>
      <button className="home-nav-right" onClick={next}>
        <ChevronRightIcon fontSize={fontSize} />
      </button>
    </div>
  );
};
