import React from "react";
import useCanvasValues from "./useCanvasValues";

const MAX_IMAGE_WIDTH = 1760;
const MAX_IMAGE_HEIGHT = 1080;

const MAX_WIDTH = 985;
const MAX_HEIGHT = 605;
let currentWidth = MAX_WIDTH;
let currentHeight = MAX_HEIGHT;
const DISTANCE = 25;

const transX = (x) => (Math.min(currentWidth, MAX_IMAGE_WIDTH) * x) / MAX_WIDTH;
const transY = (y) => (Math.min(currentHeight, MAX_IMAGE_HEIGHT) * y) / MAX_HEIGHT;

const fixClickedPoint = ({ xPos: x, yPos: y }) => ({
  xPos: (MAX_WIDTH * x) / Math.min(currentWidth, MAX_IMAGE_WIDTH),
  yPos: (MAX_HEIGHT * y) / Math.min(currentHeight, MAX_IMAGE_HEIGHT),
});

const distance = (p1, p2) => {
  const { xPos: x1, yPos: y1 } = p1;
  const { xPos: x2, yPos: y2 } = p2;

  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
};

const areClose = (p1, p2) => distance(p1, p2) <= DISTANCE;

const solve = (a, b, e, c, d, f, centerY, Y_OFFSET = 50) => {
  const determinant = a * d - b * c;
  if (determinant !== 0) {
    const x = (e * d - b * f) / determinant;
    const y = (a * f - e * c) / determinant;
    return { xPos: x, yPos: y };
  } else {
    if (a === 0) {
      return undefined;
    }
    const y = centerY - Y_OFFSET;
    const x = (e - b * y) / a;
    return { xPos: x, yPos: y };
  }
};

const calculateControlPoint = (p1, p2) => {
  const [a, b] = [p1.xPos, p1.yPos];
  const [c, d] = [p2.xPos, p2.yPos];
  const [e, f] = [(a + c) / 2, (b + d) / 2];

  const [w, q, s, t] = [e - a, f - b, e - c, f - d];
  const dist2 = (e - a) * (e - a) + (f - b) * (f - b);

  const dist = distance(p1, p2);

  let controlPoint = solve(w, q, dist2 + w * a + q * b, s, t, dist2 + s * c + t * d, f, (dist + 50) / dist);
  if (!controlPoint) {
    controlPoint = { xPos: e, yPos: f };
  }
  if (Math.abs(a - c) <= 75) {
    controlPoint = { xPos: e, yPos: f - 30 };
  }

  if (dist < 200) {
    controlPoint = { xPos: e, yPos: f - 60 };
  } else {
    controlPoint = { xPos: e, yPos: f - 150 };
  }
  return controlPoint;
};

const Canvas = ({ initialDots, selectedBranch, setSelectedBranch, clickedPoint, sizes, ...props }) => {
  const canvasRef = React.useRef(null);
  const { fontSize, pointRadiusScale } = useCanvasValues();

  React.useEffect(() => {
    currentWidth = sizes.width;
    currentHeight = sizes.height;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.font = `900 ${fontSize} Arial`;
    ctx.lineWidth = 2;

    const fillText = (text, x, y, isSelected = false) => {
      let textColor = "#fff";
      if (isSelected) {
        textColor = "#00B1FF";
      }
      ctx.fillStyle = textColor;
      ctx.fillText(text.split("").join(String.fromCharCode(8202)) || text, transX(x), transY(y));
    };

    const drawPoint = (point, { isMainBranch, isSelected }) => {
      let scale = pointRadiusScale;
      if (isSelected) {
        scale = pointRadiusScale / 1.3;
      }

      // const color = isSelected ? "#fff" : "#00ACF8";
      const color = "#fff";
      const secondaryColor = "rgba(0, 177, 255, 0.7)";
      const shadowColor = "rgba(0, 177, 255, 0.4)";

      const innerRadius = !isMainBranch ? 4 : 5;
      const outerRadius = !isMainBranch ? 9 : 12;
      const shadowRadius = !isMainBranch ? 14 : 16;

      ctx.beginPath();
      ctx.fillStyle = shadowColor;
      ctx.arc(transX(point.xPos), transY(point.yPos), shadowRadius / scale, 0, 2 * Math.PI, true);
      ctx.fill();

      ctx.beginPath();
      ctx.fillStyle = secondaryColor;
      ctx.arc(transX(point.xPos), transY(point.yPos), outerRadius / scale, 0, 2 * Math.PI, true);
      ctx.fill();

      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.arc(transX(point.xPos), transY(point.yPos), innerRadius / scale, 0, 2 * Math.PI, true);
      ctx.fill();
    };

    const drawArc = (p1, p2) => {
      ctx.fillStyle = "#078ECA";
      ctx.strokeStyle = "#078ECA";

      const controlPoint = calculateControlPoint(p1, p2);

      ctx.beginPath();
      ctx.moveTo(transX(p1.xPos), transY(p1.yPos));
      ctx.quadraticCurveTo(transX(controlPoint.xPos), transY(controlPoint.yPos), transX(p2.xPos), transY(p2.yPos));
      ctx.stroke();
    };

    if (initialDots.length > 0) {
      const firstPoint = initialDots[0];
      for (let i = 1; i < initialDots.length; i++) {
        const point = initialDots[i];
        drawArc(firstPoint, point);
      }
    }

    initialDots.forEach(({ xPos, yPos, name }, index) => {
      const isMainBranch = index === 0;
      const isSelected = selectedBranch !== null && selectedBranch.name === name;
      drawPoint({ xPos, yPos }, { isMainBranch, isSelected });

      if (index === 0) {
        fillText(name, xPos, yPos + 30, isSelected);
        return;
      }
      const firstPoint = initialDots[0];
      if (xPos <= firstPoint.xPos && yPos <= firstPoint.yPos) {
        fillText(name, xPos - 29, yPos - 19, isSelected);
      } else if (xPos >= firstPoint.xPos && yPos <= firstPoint.yPos) {
        fillText(name, xPos, yPos - 19, isSelected);
      } else if (xPos <= firstPoint.xPos && yPos >= firstPoint.yPos) {
        fillText(name, xPos - 29, yPos + 29, isSelected);
      } else {
        fillText(name, xPos - 29, yPos + 29, isSelected);
      }
    });

    let isFound = false;
    if (Array.isArray(initialDots) && clickedPoint !== null) {
      for (let i = 0; i < initialDots.length; i++) {
        const branch = initialDots[i];
        if (areClose(fixClickedPoint(clickedPoint), branch)) {
          setSelectedBranch(branch);
          isFound = true;
          break;
        }
      }
    }
    if (!isFound) {
      setSelectedBranch(null);
    }

    return () => ctx.clearRect(0, 0, canvas.width, canvas.height);
  }, [initialDots, clickedPoint, selectedBranch, setSelectedBranch, sizes, fontSize, pointRadiusScale]);

  return <canvas ref={canvasRef} {...props}></canvas>;
};

export default Canvas;
