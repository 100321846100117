import React from "react";
import "./OurMission.css";
import { DashedLine } from "components/DashedLine";
import IMAGE from "assets/images/our-mission.png";
import { useAboutUsDesc } from "api/aboutUs";
import { Description } from "../Description";

export const OurMission = () => {
  const { data: desc } = useAboutUsDesc();

  return (
    <div className="ab-page">
      <div className="om-image">
        <div className="om-image-wrapper">
          <img src={IMAGE} alt="" />
        </div>
      </div>
      <div className="ab-content om-content">
        <div className="ab-title">
          <h1>Our</h1>
          <h1>Mission</h1>
        </div>
        <DashedLine />
        {desc && <Description fullText={desc["OurMission"]} className="ab-desc" />}
      </div>
    </div>
  );
};
