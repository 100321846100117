import React from "react";
import "./css/branch-card.css";
import { baseURL } from "api/config";
import { Fade } from "views/animation/Fade";

const BranchDetails = ({ branch }) => {
  return (
    <Fade show={branch !== null}>
      <div className="branch_card_wrapper">
        <div className="branch_card">
          <img src={`${baseURL}/${branch?.image}`} alt="" />
          <div className="branch_info">
            <h2 className="branch_title">{branch?.name}</h2>
            <pre className="branch_description">{branch?.description}</pre>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default BranchDetails;
