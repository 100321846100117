import React from "react";
import { useAppearingText } from "views/animation";
import "./css/title.css";

export const Title = () => {
  const title = useAppearingText("BRANCHES", { speed: 1, initialChars: 1, interval: 50 });

  return (
    <div className="b-title-container">
      <h1 className="b-title">{title}</h1>
      <div className="b-line">
        <div className="b-line-first"></div>
        <div className="b-line-second"></div>
        <div className="b-line-third"></div>
      </div>
    </div>
  );
};
