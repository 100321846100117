import React from "react";
import "./about-us-navs.css";
import { Fade } from "views/animation";
import { AboutUs } from "./AboutUs";
import { OurMission } from "./OurMission";
import { OurVision } from "./OurVision";
import "./AboutUs/AboutUs.css";

const tabs = [
  {
    key: 0,
    label: "About Us",
    content: <AboutUs />,
    className: "ab-bg",
  },
  {
    key: 1,
    label: "Our Mission",
    content: <OurMission />,
    className: "om-bg",
  },
  {
    key: 2,
    label: "Our Vision",
    content: <OurVision />,
    className: "ov-bg",
  },
];

const AboutUsPage = () => {
  const [activeKey, setActiveKey] = React.useState(0);

  return (
    <div className={`about-us-container ${tabs[activeKey].className}`}>
      <div className="ab-navs-container">
        {tabs.map((tab) => (
          <div className={`ab-nav-item ${tab.key === activeKey ? "ab-nav-active" : ""}`} key={tab.key} onClick={() => setActiveKey(tab.key)}>
            {tab.label}
            <div className={`ab-dot ${tab.key === activeKey ? "ab-active-dot" : ""}`}></div>
          </div>
        ))}
      </div>

      <div className="">
        {tabs.map((tab) => (
          <Fade key={tab.key} show={tab.key === activeKey}>
            {tab.key === activeKey && tab.content}
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default AboutUsPage;
