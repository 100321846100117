import React from "react";
import "./SlidersRadio.css";
import { twoDigitsNum } from "helpers/numbers";

export const SlidersRadio = ({ activeSlider, setActiveSlider, totalSlides, style }) => {
  const total = React.useMemo(() => {
    const arr = [];
    for (let i = 0; i < totalSlides; i++) arr.push(i);
    return arr;
  }, [totalSlides]);

  return (
    <div className="sliders-radio-wrapper" style={style}>
      <div>
        <span className="h-num">{twoDigitsNum(totalSlides > 0 ? activeSlider + 1 : 0)}</span>
        <span className="h-total"> /{twoDigitsNum(totalSlides)}</span>
      </div>
      {total.map((idx) => (
        <div key={idx} className={`h-checkbox ${idx === activeSlider ? "h-checkbox-active" : ""}`} onClick={() => setActiveSlider(idx)}></div>
      ))}
    </div>
  );
};
