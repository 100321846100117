import React from "react";
import "./CloseButton.css";
import { useContextLayout } from "views/layout/ContextLayout";
import CloseIcon from "@mui/icons-material/Close";
import { useBreakPointsValues } from "hooks";

export const CloseButton = () => {
  const { setSidebarOpen } = useContextLayout();
  const fontSize = useBreakPointsValues("small", "medium", "large", { left: 768, right: 1400 });

  return (
    <div className="close-button-container">
      <button className="close-button" onClick={() => setSidebarOpen(false)}>
        <CloseIcon fontSize={fontSize} />
      </button>
    </div>
  );
};
