import "./App.css";
import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "helpers/history";
import HomePage from "views/Home/HomePage";
import ContactUs from "views/contact-us/ContactUs";
import AboutUsPage from "views/about-us/AboutUsPage";
import Gallery from "views/gallery/Gallery";
import BranchesPage from "views/branches/BranchesPage";
import OurProductsPage from "views/our-products/OurProductsPage";
import { Layout } from "views/layout/Layout";
import { ContextProvidor, useContextLayout } from "views/layout/ContextLayout";
import { getTitle, paths } from "configs/navConfig";

const LayoutRoute = ({ component: Component, ...rest }) => {
  const { setSidebarOpen, websiteEntered } = useContextLayout();
  useEffect(() => {
    if (websiteEntered) {
      setSidebarOpen(false);
    }
  }, [rest.location, setSidebarOpen, websiteEntered]);

  useEffect(() => {
    if (!paths.includes(rest.location.pathname)) {
      history.replace("/");
    } else {
      document.title = getTitle(rest.location.pathname);
    }
  }, [rest.location]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

function App() {
  return (
    <ContextProvidor>
      <Router history={history}>
        <Switch>
          <LayoutRoute path="/contact-us" component={ContactUs} />
          <LayoutRoute path="/about-us" component={AboutUsPage} />
          <LayoutRoute path="/our-products" component={OurProductsPage} />
          <LayoutRoute path="/gallery" component={Gallery} />
          <LayoutRoute path="/branches" component={BranchesPage} />
          <LayoutRoute path="/" component={HomePage} />
        </Switch>
      </Router>
    </ContextProvidor>
  );
}

export default App;
