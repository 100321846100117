import React from "react";

export const ContextLayout = React.createContext();

export const ContextProvidor = (props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const [websiteEntered, setWebsiteEntered] = React.useState(false);

  return (
    <ContextLayout.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        websiteEntered,
        setWebsiteEntered,
      }}
    >
      {props.children}
    </ContextLayout.Provider>
  );
};

export const useContextLayout = () => React.useContext(ContextLayout);
