import { useQuery } from "react-query";
import axios from "axios";

// export const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}`;
// export const baseURL = `${window.location.protocol}//${window.location.hostname}:8000`;

//const { protocol, port, hostname } = window.location;
//const _host = hostname.split(".").slice(-2).join(".");
//export const baseURL = `${protocol}//api.${_host}${port ? ":" + port : ""}`;

// export const baseURL = "http://localhost:8000";
export const baseURL = "https://api.skyhawks.biz";

export const useGetQuery = (key, url) => {
  return useQuery(key, async () => {
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (err) {}
  });
};

// export const useGetQuery = (key, url) => {
//   return useQuery(key, () => fakeBackend[key]);
// };

/*
const fakeBackend = {
  CONTACT_US_SERVICES: ["Service1", "Service2", "Service3", "Service4"],
  GALLERY_IMAGES: [
    "/gallery/1.png",
    "/gallery/2.png",
    "/gallery/3.png",
    "/gallery/4.png",
    "/gallery/5.png",
    "/gallery/6.png",
    "/gallery/7.png",
    "/gallery/8.png",
    "/gallery/9.png",
    "/gallery/10.png",
    "/gallery/11.png",
    "/gallery/12.png",
    "/gallery/13.png",
    "/gallery/14.png",
    "/gallery/15.png",
    "/gallery/16.png",
    "/gallery/17.png",
  ],
  SOCIAL_LINKS: {
    facebook: "https://www.facebook.com",
    instagram: "https://www.instagram.com",
    whatsapp: "https://www.whatsapp.com",
    linkedin: "https://www.linkedin.com",
  },
  BRANCHES: {
    uae: {
      id: "uae",
      title: "UAE",
      location: "Sky Hawks Intl ,Trading LLC Dubai–Maktoum Hospital Road",
      mobile: "+971569055177",
      wechatID: "izzy_naily",
    },
    sudan: {
      id: "sudan",
      title: "SUDAN",
      location: "MAZCO Import & Export , Khartoum3 – Tyres Market",
      mobile: "+249965290184",
      wechatID: "wxid_motrvoexl42i12",
    },
    china: {
      id: "china",
      title: "CHINA",
      location: "Qingdao Sky Hawks Intl , Trading Qingdao – Laoshan, Haier Road",
      mobile: "+861396965885",
      wechatID: "Ahmad-naily",
    },
  },
  GALLERY_DESC: "Lorem Ipsum is simply dummy text of the printingtypesetting industry type specime with PageMaker including versions of Lorem Ipsum.",
  ABOUT_US_DESC: {
    AboutUs:
      "Sky hawks is a company specialized in import and distribute tires, Batteries and lubricants in the middle east area and Africa, the head office is located in Dubai, Sky Hawks, have branches in some countries, such as China, Sudan and others countries , with many exclusive agency of the important brands world wide",
    OurMission:
      "Sky hawks aims to delivering the demands of the Middle Eastern and African markets, from tires, batteries and lubricants, with suitable quality and competitive prices, which followed with the best services throughout working with inveterate manufacturers and dealing with the best supply chains in the area, also with the effort of our loyal team.",
    OurVision: "To become one of the main and highly trusted international companies in the Middle East and Africa .",
  },
  HOME_SLIDERS: [
    {
      text: "Lorem Ipsum is simply dummy text of the printingtypesetting industry. Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/home/home-bg-1.png",
    },
    {
      text: "Lorem Ipsum is simply dummy text of the printingtypesetting industry. Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/home/home-bg-2.png",
    },
    {
      text: "Lorem Ipsum is simply dummy text of the printingtypesetting industry. Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/home/home-bg-3.png",
    },
  ],
  PRODUCTS: [
    {
      product_name: "TIRES",
      header: "Lorem Ipsum Is Simply",
      description:
        "Lorem Ipsum is simply dummy text of the printingtypesetting industry Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/products/tire.png",
      brands: [
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
      ],
    },
    {
      product_name: "OILS",
      header: "Lorem Ipsum Is Simply",
      description:
        "Lorem Ipsum is simply dummy text of the printingtypesetting industry Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/products/oil.png",
      brands: [
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
      ],
    },
    {
      product_name: "Batteries",
      header: "Lorem Ipsum Is Simply",
      description:
        "Lorem Ipsum is simply dummy text of the printingtypesetting industry Lorem Ipsum has been the industry's standard since the make a type specime with PageMaker including versions of Lorem Ipsum.",
      image: "/products/battery.png",
      brands: [
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/2.png",
          file: "/brands/2.png",
        },
        {
          image: "/brands/3.png",
          file: "/brands/3.png",
        },
        {
          image: "/brands/1.png",
          file: "/brands/1.png",
        },
      ],
    },
  ],
};
*/
