import React from "react";
import { twoDigitsNum } from "helpers/numbers";
import { useBreakPointsValues } from "hooks";

export const Scroll = ({ active, total }) => {
  const totalWidth = useBreakPointsValues(33, 74, 100);
  const width = totalWidth / total;
  const trans = active * width;

  return (
    <div className="pro-scroll-container">
      <span className="pro-scroll" style={{ width: `${totalWidth}px` }}>
        <span
          className="pro-scroll-active"
          style={{
            width: `${width}px`,
            transform: `translateX(${trans}px)`,
          }}
        ></span>
      </span>
      <span className="pro-active-number">{twoDigitsNum(active + 1)} </span>
      <span className="pro-total-number">/ {twoDigitsNum(total)}</span>
    </div>
  );
};
