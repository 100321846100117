import React from "react";
import "./btn.css";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";

const labels = ["SEND MESSAGE", <CheckIcon fontSize="large" />];

export const SubmitButton = ({ isLoading }) => {
  const [activeLabel, setActiveLabel] = React.useState(labels[0]);

  return (
    <div className="submit-btn-container" onMouseOver={() => setActiveLabel(labels[1])} onMouseLeave={() => setTimeout(() => setActiveLabel(labels[0]), 500)}>
      <button type="submit" className="submit-button">
        {isLoading ? <CircularProgress style={{ color: "#fff" }} /> : activeLabel}
      </button>
    </div>
  );
};
