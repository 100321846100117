import React from "react";
import "./NavLinks.css";
import { navConfig } from "configs/navConfig";
import { Link, useLocation } from "react-router-dom";

export const NavLinks = () => {
  const location = useLocation();

  return (
    <div className="navs-container">
      {navConfig.map((nav) => (
        <Link to={nav.navLink} key={nav.id} className={`nav-item ${location.pathname === nav.navLink ? "nav-active" : ""}`}>
          {nav.label}
        </Link>
      ))}
    </div>
  );
};
