import React from "react";
import { Fade } from "views/animation";
import NavPage from "views/navPage/NavPage";
import { useContextLayout } from "../ContextLayout";
import LOGO from "assets/logo.png";
import OPEN_SIDEBAR_ICON from "assets/icons/open-sidebar.png";
import { SocialLinks } from "./SocialLinks";
import { EntryLogo } from "./EntryLogo";

const Sidebar = () => {
  const { sidebarOpen: isOpen, setSidebarOpen: setIsOpen, websiteEntered } = useContextLayout();

  return (
    <>
      <div className={`sidebar sidebar-${isOpen ? "open" : "closed"}`}>
        <Fade show={!isOpen}>
          <div className="side-bar-closed-content">
            <img className="sky-logo" src={LOGO} alt="logo" />
            <img className="open-sidebar-btn" onClick={() => setIsOpen(true)} src={OPEN_SIDEBAR_ICON} alt="open sidebar" />
            <SocialLinks />
          </div>
        </Fade>
        {isOpen && <>{websiteEntered ? <NavPage isOpen={isOpen} setIsOpen={setIsOpen} /> : <EntryLogo />}</>}
      </div>
    </>
  );
};

export default Sidebar;
