import React from "react";
import { useContextLayout } from "views/layout/ContextLayout";
import "./css/index.css";
import "./css/title.css";
import "./css/text.css";
import { useGetHomeSliders } from "api/home";
import { SlidersRadio } from "./SlidersRadio";
import { Nav } from "./Nav";
import { Footer } from "./Footer";
import { twoDigitsNum } from "helpers/numbers";
import { baseURL } from "api/config";

const HomePage = () => {
  const { data: sliders } = useGetHomeSliders();
  const { websiteEntered } = useContextLayout();
  const [activeSlider, setActiveSlider] = React.useState(0);
  const [playVideo, setPlayVideo] = React.useState(false);

  if (!websiteEntered || !sliders) {
    return null;
  }
  const propsToPass = {
    activeSlider,
    setActiveSlider,
    totalSlides: sliders.length,
  };
  const togglePlay = () => {
    setPlayVideo((v) => !v);
  };
  const style = playVideo ? { position: "fixed", zIndex: 2 } : {};

  return (
    <>
      <div className="curtain" style={style}>
        <h1>{twoDigitsNum(sliders.length === 0 ? 0 : activeSlider + 1)}</h1>
      </div>
      <div className="home-page" style={playVideo ? { ...style, background: "transparent" } : { backgroundImage: `url("${baseURL}/${sliders[activeSlider]?.image}")` }}>
        <div className="full-title-wrapper">
          <div className="sky-title-wrapper">
            <h1 className="sky-title">Sky</h1>
            <div className="sky-line-bottom"></div>
          </div>
          <h1 className="hawks-title">Hawks</h1>
        </div>
        <div>
          <p className="home-text">{sliders && sliders.length > 0 && sliders[activeSlider].text}</p>
        </div>
      </div>
      <SlidersRadio {...propsToPass} style={style} />
      <Nav {...propsToPass} style={style} />
      <Footer {...propsToPass} playVideo={playVideo} togglePlay={togglePlay} />
    </>
  );
};

export default HomePage;
