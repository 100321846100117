import React from "react";
import { useLocation } from "react-router-dom";
import FULLLOGO from "assets/full-logo.png";
import { useContextLayout } from "../ContextLayout";
import "./styles.css";
import { useSocialLinks } from "api/siteConfig";
import { useGetHomeSliders } from "api/home";

const HomePagePrefetch = () => {
  useGetHomeSliders();
  return null;
};

export const EntryLogo = () => {
  useSocialLinks();
  const { setSidebarOpen: setIsOpen, setWebsiteEntered } = useContextLayout();
  const location = useLocation();

  return (
    <div
      className="entry-container"
      onClick={() => {
        setIsOpen(false);
        setWebsiteEntered(true);
      }}
    >
      <div>{location.pathname === "/" && <HomePagePrefetch />}</div>
      <img className="entry-full-logo" style={{ cursor: "pointer" }} src={FULLLOGO} alt="full-logo" />
      <footer className="entry-footer">© 2017 SKY HAWKS - All rights reserved</footer>
    </div>
  );
};
