import React from "react";
import { useTimeout } from "hooks";
import { Fade } from "views/animation";
import { useContextLayout } from "views/layout/ContextLayout";
import { ContactUsButton } from "./ContactUsButton";
import FULL_WHITE_LOGO from "assets/full-logo-white.png";
import "./navLayout.css";
import { CloseButton } from "./CloseButton";
import { NavLinks } from "./NavLinks";
import { useWindowSize } from "hooks";

const breakpoint = 1199;

const NavPage = ({ isOpen, setIsOpen }) => {
  const { websiteEntered } = useContextLayout();
  const [showNavs, setShowNavs] = React.useState(false);
  useTimeout(() => {
    setShowNavs(true);
  }, 1500);
  const { width } = useWindowSize();

  return (
    <div className={isOpen && websiteEntered ? "content-open-wrapper" : ""}>
      <Fade show={showNavs}>
        <div className="content-open">
          <div className="d-flex justify-content-between">
            <img className="full-white-logo" src={FULL_WHITE_LOGO} alt="FULL_WHITE_LOGO" />
            {width <= breakpoint ? <CloseButton /> : <ContactUsButton />}
          </div>
          <div className="nav-content-container">
            {width <= breakpoint ? (
              <>
                <NavLinks />
                <ContactUsButton />
              </>
            ) : (
              <>
                <CloseButton />
                <NavLinks />
              </>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default NavPage;
