import React from "react";
import "./SelectInput.css";
import { useFormikContext } from "formik";

export const SelectInput = ({ label, name, options }) => {
  const formik = useFormikContext();

  return (
    <select
      name={name}
      placeholder={label}
      value={formik.values[name]}
      onChange={(e) => formik.setFieldValue(name, e.target.value)}
      className={`s-row select ${formik.values[name] !== options[0] ? "selected-value" : ""}`}
    >
      {options.map((service) => (
        <option value={service} key={service}>
          {service}
        </option>
      ))}
    </select>
  );
};
