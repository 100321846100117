import { useState, useEffect } from "react";
import { useWindowSize } from "hooks/useWindowSize";

const useCanvasValues = () => {
  const { width } = useWindowSize();
  const [fontSize, setFontSize] = useState("1rem");
  const [pointRadiusScale, setPointRadiusScale] = useState(1);

  useEffect(() => {
    if (width <= 375) {
      setFontSize("0.5rem");
    } else if (width > 375 && width <= 768) {
      setFontSize("0.75rem");
    } else {
      setFontSize("1.1rem");
    }
  }, [width]);

  useEffect(() => {
    if (width < 600) {
      setPointRadiusScale(2);
    } else {
      setPointRadiusScale(1);
    }
  }, [width]);

  return { fontSize, pointRadiusScale };
};

export default useCanvasValues;
