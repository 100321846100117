import React from "react";
import { DashedLine } from "components/DashedLine";
import IMAGE from "assets/images/about-us.png";
import { useAboutUsDesc } from "api/aboutUs";
import { Description } from "../Description";

export const AboutUs = () => {
  const { data: desc } = useAboutUsDesc();

  return (
    <div className="ab-page">
      <div className="ab-image">
        <div className="ab-image-wrapper">
          <img src={IMAGE} alt="" />
        </div>
      </div>
      <div className="ab-content">
        <div className="ab-title">
          <h1>About</h1>
          <h1>Us</h1>
        </div>
        <DashedLine />
        {desc && <Description fullText={desc["AboutUs"]} className="ab-desc" />}
      </div>
    </div>
  );
};
