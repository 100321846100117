import { useState, useEffect } from "react";
import { useTimeout } from "hooks";

const defaultOptions = {
  speed: 45,
  delay: 700,
  initialChars: 10,
  interval: 100,
};

export const useAppearingText = (fullText, options = {}) => {
  const initialChars = options.initialChars || defaultOptions.initialChars;
  const delay = options.delay || defaultOptions.delay;
  const speed = options.speed || defaultOptions.speed;
  const interval = options.interval || defaultOptions.interval;

  const [index, setIndex] = useState(initialChars);
  const [text, setText] = useState(fullText.substring(0, initialChars));
  const [start, setStart] = useState(false);

  useTimeout(() => {
    setStart(true);
  }, delay);

  useEffect(() => {
    if (start && index <= fullText.length) {
      setTimeout(() => {
        setIndex((idx) => Math.min(idx + speed, fullText.length));
        setText(fullText.substring(0, index));
      }, interval);
    }
  }, [index, fullText, start, speed, interval]);

  return text;
};
