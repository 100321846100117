import React from "react";
import "./Gallery.css";
import { useAppearingText } from "views/animation";
import { useGalleryImages, useGalleryDesc } from "api/gallery";
import { ImageSliderLayout } from "./ImageSliderLayout";

const Gallery = () => {
  const { data: images } = useGalleryImages();
  const { data: desc } = useGalleryDesc();
  const title = useAppearingText("Gallery", { initialChars: 1, speed: 1 });

  return (
    <div className="gallery-page">
      <div className="gallery-content">
        <h1 className="g-title">{title}</h1>
        <p className="g-desc">{desc}</p>
      </div>
      <div className="g-images">
        <ImageSliderLayout images={images} />
      </div>
    </div>
  );
};

export default Gallery;
