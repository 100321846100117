import React from "react";
import "./OurProducts.css";
import "./texts.css";
import { ProductsSlider } from "./ProductsSlider";
import BrandsList from "./Brands/BrandsList";

const OurProductsPage = () => {
  const [active, setActive] = React.useState(0);

  return (
    <div className="our-products-page">
      <div className="products-section">
        <ProductsSlider active={active} setActive={setActive} />
      </div>
      <div className="brands-section">
        <BrandsList active={active} />
      </div>
    </div>
  );
};

export default OurProductsPage;
