import React from "react";
import { useProducts } from "api/products";
import "./BrandsList.css";
import { baseURL } from "api/config";

const BrandsList = ({ active }) => {
  const { data } = useProducts();
  if (!data) return null;
  if (data && data.length === 0) return null;

  const brands = data[active].brands;
  return (
    <div className="brands-container">
      {brands?.map((brand, index) => (
        <a className="brand-img-container" key={index} href={`${baseURL}/${brand.file}`} target="_blank" rel="noreferrer">
          <img className="brand-img" src={`${baseURL}/${brand.image}`} alt="brand" />
        </a>
      ))}
    </div>
  );
};

export default BrandsList;
